const appName = 'app';
const prefix = process.env.GATSBY_PREFIX || '';

const domain =
  process.env.GATSBY_DOMAIN ||
  'https://api.nomox.it' ||
  'http://localhost:8000';

const sso_domain = process.env.GATSBY_SSO_DOMAIN || 'https://sso.nomox.it';
const sso_realms =
  process.env.GATSBY_STAGE === 'prd' || process.env.GATSBY_STAGE === 'stg'
    ? 'nomox'
    : 'Test';

const irnerioDocumentsProvider =
  'https://ke8uqcbh54.execute-api.eu-west-1.amazonaws.com/prd';
const dueDiligenceProvider =
  'https://nsa82zpx0c.execute-api.eu-west-1.amazonaws.com/prd';

const generalProvider =
  process.env.GATSBY_GESIONALE_PROVIDER ||
  // 'https://ab2wzhb0mb.execute-api.eu-west-1.amazonaws.com/stg' ||
  // 'https://byyhyygmea.execute-api.eu-west-1.amazonaws.com/prd' ||
  'http://localhost:3000';

export const creditiMap = {
  pec: 0.5,
  visuraCatastale: 3,
  visuraIpocatastale: 30,
};

const sso_scopes =
  'openid,offline_access,User.Read,Files.ReadWrite.All,Sites.ReadWrite.All';

const sso_client_id = '03d4905d-833a-4430-82a3-7c771caa6bfe';

export const rootConfig = {
  isDebug: !process.env.GATSBY_DOMAIN,
  language: 'it',
  url: `${domain}/api`,
  irnerioDocumentsProvider: `${irnerioDocumentsProvider}`,
  urlDueDiligence: `${dueDiligenceProvider}`,
  endpointGestionale: `${generalProvider}/gestionale`,
  beEndpoint: `${generalProvider}`,
  endpointDrive: `${generalProvider}/drive`,
  app_base_path: `/${appName}`,
  app_path: `${prefix}/${appName}`,
  upload_url: `${domain}/api/uploadAudio.php`,
  audio_folder: `${domain}/api/uploads/audio/`,
  base_url: `${domain}`,
  wp_url: `${domain}/?rest_route=`,
  sso_endpoint: `${sso_domain}/realms/nomox/protocol/openid-connect`,
  sso_client_id: `nomox${
    process.env.GATSBY_STAGE === 'prd' ? '' : `-${process.env.GATSBY_STAGE}`
  }`,
  sso_login_ms_personal: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${sso_client_id}&response_type=code&scope=${sso_scopes}`,
  sso_admin_consent: `https://login.microsoftonline.com/common/adminconsent?client_id=${sso_client_id}`,
  stage: process.env.GATSBY_STAGE,
  endpointWpGestionale: `${domain}/?rest_route=/gestionale/v1`,
  modalTimeout: 600,
  pageSize: 10,
  populateFields: 101,
  innerPageSize: '95%',
};

export const buildLoginBaseUrlForTenant = (tenant: string) => {
  return `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?client_id=${sso_client_id}&response_type=code&scope=${sso_scopes}`;
};
